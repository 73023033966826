import axios from 'axios'
import { API_URL } from '@/utils/constants'

export const getStops = (params = {}, headers = {}) => {
  return axios.get(`${API_URL}/stops`, {
    params,
    headers: { ...headers }
  })
}

export const getRouteStops = (params = {}, headers = {}) => {
  return axios.get(`${API_URL}/routestop`, {
    params: { ...params, take: 1000 },
    headers: { ...headers }
  })
}
