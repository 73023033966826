import styled from 'styled-components'
import YellowPaint from '@/assets/images/clients/genesis/home/yellow-paint.png'
import { Select, Input } from 'antd'

export const LogCol = styled.div`
  background-color: #ccc;
  height: 100vh;
  overflow: auto;
  padding: 10px;
`

export const PageContainer = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1250px;
  }
`

export const StyledPrefixedSelect = styled.div`
  position: relative;
  .layag-icons {
    position: absolute;
    z-index: 1;
    left: 12px;
    bottom: 11px;
  }
  .ant-select.ant-select-lg {
    width: 100%;
    border: 1px solid #DFE3E8;
    border-radius: 4px;

    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-select-selection-search-input {
      padding-left: 22px !important;
    }
    .ant-select-selector {
      border: 0 !important;
    }
  }
`

export const BusType = styled.div`
  margin-top: 7px;
  height: 80px;
  text-align: center;
  border: 1px solid #DFE3E8;
  box-sizing: border-box;
  border-radius: 8px;
  color: #222222;
  cursor: pointer;
  img {
    margin-top: 14px;
  }
  &.selected, &:hover {
    border-color: #222222;
    color: #222222;
  }
`

export const StyledInput = styled(Input)`
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  height: 57px;
  font-size: 16px;
`

export const StyledSelect = styled(Select)`
  width: 100%;
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
    height: 57px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    font-size: 16px;
  }
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 57px;
  }
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 57px;
  }
  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 57px;
  }
`

export const StyledLoader = styled.div`
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 40%;

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(238,58,66);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`

export const StyledTitleHead = styled.div`
  margin: 0 auto;
  display: inline-block;
  background-image: url(${YellowPaint});
  background-repeat: no-repeat;
  background-position: left;
  padding: 16px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
`
