import { useEffect } from 'react'
import { useFormik } from 'formik'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'
import validationSchema from './validations'

export default ({ children }) => {
  const state = useStoreState(s => ({
    auth: s.auth,
    authModal: s.authModal
  }))
  const actions = useStoreActions(a => ({
    setAuth: a.setAuth,
    authenticate: a.authenticate,
    setAuthModal: a.setAuthModal
  }))
  const formik = useFormik({
    initialValues: {
      username: 'demo@bukobus.com',
      password: 'u2f9wBHJZbVUEz'
    }, // Initial form validations
    validationSchema, // From validations
    enableReinitialize: true,
    onSubmit(values) {
      actions.authenticate(values)
    }
  })

  useEffect(() => {
    const { status, payload } = state.auth
    if (status === 2) {
      sessionStorage.setItem('auth', JSON.stringify(payload))
      actions.setAuth({ status: 0, payload: null })
      actions.setAuthModal(false)
      setTimeout(() => {
        window.location.reload()
      }, 100)
    } else if (status === 3) {
      actions.setAuth({ status: 0, payload: null })
      message.error('Invalid Credentials')
    }
  }, [state.auth])

  return children({
    formik,
    state
  })
}
