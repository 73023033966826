import { thunk, action } from 'easy-peasy'
import { getStops, getRouteStops } from '@/api/stops'

export default {
  // Stops
  stops: { status: 0, payload: null },
  setStops: action((state, payload = null) => {
    state.stops = payload
  }),
  getStops: thunk(async (actions, params = {}) => {
    actions.setStops({ status: 1 })
    getStops(params)
      .then(response => actions.setStops({ status: 2, payload: response.data }))
      .catch(e => actions.setStops({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Route Stops
  routeStops: { status: 0, payload: null },
  setRouteStops: action((state, payload = null) => {
    state.routeStops = payload
  }),
  getRouteStops: thunk(async (actions, params = {}) => {
    actions.setRouteStops({ status: 1 })
    getRouteStops(params)
      .then(response => actions.setRouteStops({ status: 2, payload: response.data }))
      .catch(e => actions.setRouteStops({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
