import React from 'react'
import swapIcon from '../assets/images/icons/swap.svg'
import busIcon from '../assets/images/icons/bus.svg'
import mapIcon from '../assets/images/icons/map.svg'
import calendarIcon from '../assets/images/icons/calendar.svg'
import arrowDownIcon from '../assets/images/icons/arrow-down.svg'
import airconIcon from '../assets/images/icons/aircon.svg'
import airconDarkIcon from '../assets/images/icons/aircon-dark.svg'
import xIcon from '../assets/images/icons/x.svg'
import clockIcon from '../assets/images/icons/clock.svg'
import saveMoneyIcon from '../assets/images/icons/save-money.svg'
import shieldCheckIcon from '../assets/images/icons/shield-check.svg'
import restroomIcon from '../assets/images/icons/restroom.svg'
import tvIcon from '../assets/images/icons/tv.svg'
import wifiIcon from '../assets/images/icons/wifi.svg'
import reverseLgIcon from '../assets/images/icons/reverse-lg.svg'
import bdoIcon from '../assets/images/icons/banks/bdo.svg'
import bpiIcon from '../assets/images/icons/banks/bpi.svg'
import chinabankIcon from '../assets/images/icons/banks/chinabank.svg'
import rcbcIcon from '../assets/images/icons/banks/rcbc.svg'
import ucpbIcon from '../assets/images/icons/banks/ucpb.svg'
import unionbankIcon from '../assets/images/icons/banks/unionbank.svg'
import printIcon from '../assets/images/icons/print.svg'
import saveIcon from '../assets/images/icons/save.svg'

export const SwapIcon = () => (
  <img src={swapIcon} className='layag-icons' alt='' />
)

export const BusIcon = () => (
  <img src={busIcon} className='layag-icons' alt='' />
)

export const MapIcon = () => (
  <img src={mapIcon} className='layag-icons' alt='' />
)

export const CalendarIcon = () => (
  <img src={calendarIcon} className='layag-icons' alt='' />
)

export const ArrowDownIcon = () => (
  <img src={arrowDownIcon} className='layag-icons' alt='' />
)

export const AirconIcon = () => (
  <img src={airconIcon} className='layag-icons' alt='' />
)

export const AirconDarkIcon = () => (
  <img src={airconDarkIcon} className='layag-icons' alt='' />
)

export const XIcon = () => (
  <img src={xIcon} className='layag-icons' alt='' />
)

export const ClockIcon = () => (
  <img src={clockIcon} className='layag-icons' alt='' />
)

export const SaveMoneyIcon = () => (
  <img src={saveMoneyIcon} className='layag-icons' alt='' />
)

export const ShieldCheckIcon = () => (
  <img src={shieldCheckIcon} className='layag-icons' alt='' />
)

export const RestroomIcon = () => (
  <img src={restroomIcon} className='layag-icons' alt='' />
)

export const TvIcon = () => (
  <img src={tvIcon} className='layag-icons' alt='' />
)

export const WifiIcon = () => (
  <img src={wifiIcon} className='layag-icons' alt='' />
)

export const ReverseLgIcon = () => (
  <img src={reverseLgIcon} className='layag-icons' alt='' />
)

export const BdoIcon = () => (
  <img src={bdoIcon} className='layag-icons' alt='' />
)

export const BpiIcon = () => (
  <img src={bpiIcon} className='layag-icons' alt='' />
)

export const ChinabankIcon = () => (
  <img src={chinabankIcon} className='layag-icons' alt='' />
)

export const RcbcIcon = () => (
  <img src={rcbcIcon} className='layag-icons' alt='' />
)

export const UcpbIcon = () => (
  <img src={ucpbIcon} className='layag-icons' alt='' />
)

export const UnionbankIcon = () => (
  <img src={unionbankIcon} className='layag-icons' alt='' />
)

export const PrintIcon = () => (
  <img src={printIcon} className='layag-icons' alt='' />
)

export const SaveIcon = () => (
  <img src={saveIcon} className='layag-icons' alt='' />
)
