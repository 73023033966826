import React, { Suspense, lazy } from 'react'
import Loader from './components/Loader'
import PrivateRoute from './containers/PrivateRoute'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

const Simplified = lazy(() => import('./containers/Simplified'))
const CheckoutPage = lazy(() => import('./containers/CheckoutPage'))

export default () => (
  <Suspense fallback={<Loader />}>
    <PrivateRoute>
      {() => (
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Simplified} />
            <Route path='/checkout/:type?' component={CheckoutPage} />
          </Switch>
        </BrowserRouter>
      )}
    </PrivateRoute>
  </Suspense>
)
