import React from 'react'
import Routes from './routes'
import Interceptors from './utils/interceptor'
import store from './store'
import { StoreProvider } from 'easy-peasy'
import 'react-datepicker/dist/react-datepicker.css'
import './assets/styles/app.less'
import './assets/styles/app.scss'

Interceptors.setupInterceptors(store)

export default () => (
  <StoreProvider store={store}>
    <Routes />
  </StoreProvider>
)
