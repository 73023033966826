import React from 'react'
import { AirconDarkIcon, WifiIcon, TvIcon, RestroomIcon } from '@/components/Icons'

export const API_URL = process.env.REACT_APP_API_URL
export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL

export const AMENITIES = [
  {
    id: 1,
    name: 'aircon',
    icon: <AirconDarkIcon />,
    description: 'Aircon'
  },
  {
    id: 2,
    name: 'wifi',
    icon: <WifiIcon />,
    description: 'Wifi'
  },
  {
    id: 3,
    name: 'comfort_room',
    icon: <RestroomIcon />,
    description: 'Comfort Room'
  },
  {
    id: 3,
    name: 'tv',
    icon: <TvIcon />,
    description: 'TV'
  }
]

export const BUS_TYPES = [
  { code: 'semi_deluxe', name: 'Semi Deluxe' },
  { code: 'deluxe', name: 'Deluxe' },
  { code: 'premium', name: 'Premium' }
]
