import { createStore } from 'easy-peasy'
import stops from './stops'
import trips from './trips'
import employee from './employee'
import booking from './booking'
import auth from './auth'

export default createStore({
  ...stops,
  ...trips,
  ...employee,
  ...booking,
  ...auth
})
