import { thunk, action } from 'easy-peasy'
import { ADMIN_API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Auth
  auth: { status: 0, payload: null },
  setAuth: action((state, payload = null) => {
    state.auth = payload
  }),
  authenticate: thunk(async (actions, data = { }) => {
    actions.setAuth({ status: 1, payload: null })
    axios.post(`${ADMIN_API_URL}/token/`, data)
      .then(response => actions.setAuth({ status: 2, payload: response.data }))
      .catch(e => actions.setAuth({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Modal
  authModal: !sessionStorage.getItem('auth'),
  setAuthModal: action((state, payload = false) => {
    state.authModal = payload
  })
}
