import { thunk, action } from 'easy-peasy'
import { getEmployees } from '@/api/employees'

export default {
  // Employees
  employees: { status: 0, payload: null },
  setEmployees: action((state, payload = null) => {
    state.employees = payload
  }),
  getEmployees: thunk(async (actions, params = {}) => {
    actions.setEmployees({ status: 1 })
    getEmployees(params)
      .then(response => actions.setEmployees({ status: 2, payload: response.data }))
      .catch(e => actions.setEmployees({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
