import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Login from '../Login'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Modal } from 'antd'

const Container = ({ children }) => {
  const state = useStoreState(s => ({ authModal: s.authModal }))
  const actions = useStoreActions(a => ({ setAuthModal: a.setAuthModal }))

  useEffect(() => {
    if (!sessionStorage.getItem('auth') && state.authModal === false) {
      actions.setAuthModal(true)
    }
  })

  return (
    <>
      {state.authModal && (
        <Modal
          visible
          title='Login'
          footer={null}
        >
          <Login />
        </Modal>
      )}
      {children({ requireLogin: state.authModal })}
    </>
  )
}

export default Container

Container.propTypes = {
  children: PropTypes.any
}
