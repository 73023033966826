import { thunk, action } from 'easy-peasy'
import { API_URL } from '@/utils/constants'
import axios from 'axios'

export default {
  // Booking
  booking: { status: 0, payload: null },
  setBooking: action((state, payload = null) => {
    state.booking = payload
  }),
  book: thunk(async (actions, data = {}) => {
    actions.setBooking({ status: 1, payload: null })
    axios.post(`${API_URL}/booking`, data)
      .then(response => actions.setBooking({ status: 2, payload: response.data }))
      .catch(e => actions.setBooking({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Update Booking
  updatedBooking: { status: 0, payload: null },
  setUpdatedBooking: action((state, payload = null) => {
    state.updatedBooking = payload
  }),
  updateBooking: thunk(async (actions, data = {}) => {
    actions.setUpdatedBooking({ status: 1, payload: null })
    const { referenceNo, values } = data
    axios.patch(`${API_URL}/booking/${referenceNo}`, values)
      .then(response => actions.setUpdatedBooking({ status: 2, payload: response.data }))
      .catch(e => actions.setUpdatedBooking({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Retrieve Booking
  bookingDetails: { status: 0, payload: null },
  setBookingDetails: action((state, payload = null) => {
    state.bookingDetails = payload
  }),
  getBookingDetails: thunk(async (actions, referenceNo) => {
    actions.setBookingDetails({ status: 1, payload: null })
    axios.get(`${API_URL}/booking/${referenceNo}`)
      .then(response => actions.setBookingDetails({ status: 2, payload: response.data }))
      .catch(e => actions.setBookingDetails({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  }),
  // Pay Booking
  paidBooking: { status: 0, payload: null },
  setPaidBooking: action((state, payload = null) => {
    state.paidBooking = payload
  }),
  payBooking: thunk(async (actions, referenceNo) => {
    actions.setPaidBooking({ status: 1, payload: null })
    axios.post(`${API_URL}/booking/${referenceNo}/pay?sendSMS=true`)
      .then(response => actions.setPaidBooking({ status: 2, payload: response.data }))
      .catch(e => actions.setPaidBooking({ status: 3, payload: typeof e.response !== 'undefined' ? e.response.data : e }))
  })
}
